import React from 'react';
import Layout from '../components/Layout';
import Scroll from '../components/Scroll';
const Fullpacking = () => (
  <Layout>
    <article id="main">
      <header>
        <h2>Full packing service</h2>
        <p>
          Why pack it yourself? Let professional movers pack it for you!
        </p>
      </header>
      <section className="wrapper style5">
        <div className="inner">
          <section>
            <header>
              <h4>A great move starts with proper packing</h4>
              <p>
                Packing takes a lot of time. And your time is valuable. Not everyone has the time to deal with what it takes to do a great job packing. Maybe you'd just feel more comfortable knowing it was done by professionals.
              </p>
            </header>
            <header>
              <h4>Carlsbad Movers will take care of all the packing</h4>
              <p>
                We bring bubble wrap, shrink wrap, tape, tape, and more tape. We bring out double thick boxes for your china, and wrap each dish in tissue paper, and bubble wrap. Built to spec wooden crates for things like curio cabinets, china cabinets, statues, and sculptures.
                Wardrobe boxes so that your clothes are still pressed and ready to wear as soon as you want them. We pack everything in the proven method we have used to ensure that your items are safe and will make the long journey to your new home.
              </p>
              <h4>What's included with full packing?</h4>
              <ul>
              <li>More cardboard boxes than you could shake a stick at</li>
              <li>Specialty boxes like wardobe boxes, and dishpacks</li>
              <li>Bubblewrap and shrinkwrap to wrap up all your items</li>
              <li>Wooden crates built on site to fit your things perfectly</li>
              <li>Mattress covers and sofa covers to protect your mattresses, sofas, and chairs</li>
              <li>All of your books, magazines, and knick knacks packed in moving boxes</li>
              
              </ul>
            </header>
          </section>
        </div>
      </section>


    </article>
  </Layout>
);
export default Fullpacking;
